 .analytics-management {
   @include dark-blur;
   position: relative;
  //  margin-right: 25px;
   max-width: 80%;
   @include notebook {
     width: 100%;
     max-width: auto;
   }
 }

 .header {
   position: relative;
   background: $light-brown;
   margin: 2px;
   padding-top: 12px;
   padding-left: 25px;
   margin-bottom: 25px;
   height: 100%;
 h2 {
   font-size: 22px;
   color: #fff;
   font-family: $lato;
   font-weight: 300;
   &::before {
     content: "\f155";
     font-family: FontAwesome;
     margin-right: 10px;
     }
   }
 }
.analytics-filter {
  position: relative;
  @include dark-blur;
  background: $light-brown;
  display: grid;
  color: white;
  grid-template-columns: 1fr 1fr;

  &.full-width {
    grid-template-columns: 1fr;
    .left {
      grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    }
  }
  .left {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    > div {
      position: relative;
    }
  }
  .right {
    display: grid;
    grid-template-columns: 1fr 1fr;
    > div {
      position: relative;
    }
  }
  .select2-container {
    min-width: 90%;
    left: 50%;
    transform: translateX(-50%);
  }
  .select2-container--default .select2-selection--single {
    border: 1px solid #fff;
    border-radius: 0;
  }
  .select2-container {
  }
  .select2-container--default .select2-results__option--highlighted[aria-selected] {
    background: #59616b;
  }
  .select2-container--default .select2-results__option[aria-selected=true] {
    background-color: #59616b;
  }
  .select2-container--default .select2-results > .select2-results__options {
    background: #59616b;
  }
  .select2-container .select2-selection--single .select2-selection__rendered {
    padding-left: 30px;
  }
// .select2-container .select2-selection--single .select2-selection__rendered::before {
//     content: '\f09d';
//     font-family: FontAwesome;
//   }
i.card-type {
  position: absolute;
  color: #fff;
  top: 4px;
  left: 8%;
  z-index: 10;
}
i.category {
  position: absolute;
  color: #fff;
  top: 4px;
  left: 8%;
  z-index: 10;
}

}
.analytics-chart {
  position: relative;
  // margin-right: 25px;
  @include dark-blur;
  width: 100%;
  margin-bottom: 20px;

  .analytics-page-nav {
      position: relative;
      width: 100%;
      height: 40px;
      border-bottom: 1px solid #fff;
      display: flex;
      margin: 25px 0 0 0;
      padding: 0 25px 0 25px;
      list-style: none;
        li {
        position: relative;
        // margin: 0 auto;
        max-width: 16%;
        flex: 1;
        text-align: center;
        padding: 5px;
        border: 1px solid transparent;
        border-bottom: none;
        @include trans;

        a {
          @include trans;
          &:hover {
            color: $blue-hover;
          }
        }
        &:nth-child(1) {
          a {
          &::before {
            content: '\f155';
            font-family: FontAwesome;
            margin-right: 5px;
            }
          }
        }
        &:nth-child(2) {
          a {
          &::before {
            content: '\f011';
            font-family: FontAwesome;
            margin-right: 5px;
            }
          }
        }
        &:nth-child(3) {
          a {
          &::before {
            content: '\f01e';
            font-family: FontAwesome;
            margin-right: 5px;
            }
          }
        }
        &:nth-child(4) {
          a {
          &::before {
            content: '\f1b2';
            font-family: FontAwesome;
            margin-right: 5px;
            }
          }
        }
      }
      .active {
        border-color: #fff;
      }
    }
  }
  .button-holder {
    display: flex;
    position: relative;
    max-width: 45%;
    width: 35%;
    margin: 10px 0 10px 0;
    float: right;
    @include notebook {
      width: 45%;
    }
  a.daily {
    position: relative;
    color: #fff;
    font-size: 14px;
    padding: 3px 10px 3px 10px;
    border-radius: 5px;
    min-width: 80px;
    text-align: center;
    border: 1px solid transparent;
    margin: 0 auto;

  }
  .active {
    border: 1px solid #fff !important;
  }
  a.monthly {
    position: relative;
    color: #fff;
    font-size: 14px;
    padding: 3px 10px 3px 10px;
    border-radius: 5px;
    min-width: 80px;
    text-align: center;
    border: 1px solid transparent;
    margin: 0 auto;

  }
  a.yearly {
    position: relative;
    color: #fff;
    font-size: 14px;
    padding: 3px 10px 3px 10px;
    border-radius: 5px;
    min-width: 80px;
    text-align: center;
    border: 1px solid transparent;
    margin: 0 auto;
  }
}

.product-type {
  position: relative;
  @include dark-blur;
  max-width: 30%;
  margin-left: 10px;
  canvas {
    // min-width: 350px;
  }
  @include ipad {
    max-width: 100%;
    min-height: 460px;
    canvas {
         max-width: 400px !important;
         left: 50%;
         margin-left: -200px;
    }
  }
  h2 {
    color: #fff;
    text-align: center;
    position: relative;
    margin-top: 10px;
    margin-bottom: 40px;
    font-size: 24px;
  }
}
