@font-face {
  font-family: 'Glyphicons Halflings';
  src: url('./fonts/glyphicons-halflings-regular.eot');
  src: url('./fonts/glyphicons-halflings-regular.eot?#iefix') format('embedded-opentype'), url('./fonts/glyphicons-halflings-regular.woff2') format('woff2'), url('./fonts/glyphicons-halflings-regular.woff') format('woff'), url('./fonts/glyphicons-halflings-regular.ttf') format('truetype'), url('./fonts/glyphicons-halflings-regular.svg#glyphicons_halflingsregular') format('svg');
}
#datetimepicker1 {
display: block;
margin: 15px auto;
// margin-right: 25px;
background: $light-brown;
box-shadow: none;
outline: none;
border: 1px solid white;
color: white;
text-align: center;
font-family: 'Lato';
height: 28px;
font-size: 14px;
padding: 2px;
}
label.analitycs {
display: block;
float: left;
margin-top: 15px;
margin-left: 10%;
color: #fff;
}

#datetimepicker2 {
display: block;
margin: 15px auto;
background: $light-brown;
box-shadow: none;
outline: none;
border: 1px solid white;
color: white;
text-align: center;
font-family: 'Lato';
height: 28px;
font-size: 14px;
padding: 2px;
}

.glyphicon {
  position: relative;
  top: 1px;
  display: inline-block;
  font-family: 'Glyphicons Halflings';
  font-style: normal;
  font-weight: normal;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.glyphicon-time:before {
  content: "\e023";
}

.glyphicon-chevron-left:before {
  content: "\e079";
}

.glyphicon-chevron-right:before {
  content: "\e080";
}

.glyphicon-chevron-up:before {
  content: "\e113";
}

.glyphicon-chevron-down:before {
  content: "\e114";
}

.glyphicon-calendar:before {
  content: "\e109";
}

.btn {
  display: inline-block;
  padding: 6px 12px;
  margin-bottom: 0;
  font-size: 14px;
  font-weight: normal;
  line-height: 1.42857143;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  -ms-touch-action: manipulation;
  touch-action: manipulation;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  background-image: none;
  border: 1px solid transparent;
  border-radius: 4px;
}

.collapse {
  display: none;
}

  .collapse.in {
      display: block;
  }

.dropdown-menu {
  position: absolute;
  left: 0;
  margin-top: 25px;
  z-index: 1000;
  display: none;
  min-width: 160px;
  padding: 6px;
  // margin: 2px 0 0;
  font-size: 14px;
  text-align: center;
  list-style: none;
  background-color: $light-brown;
  color: #fff;
  -webkit-background-clip: padding-box;
  background-clip: padding-box;
  border: 1px solid #ccc;
  border: 1px solid rgba(0, 0, 0, .15);
  border-radius: 4px;
  -webkit-box-shadow: 0 6px 12px rgba(0, 0, 0, .175);
  box-shadow: 0 6px 12px rgba(0, 0, 0, .175);

}

.list-unstyled {
  padding-left: 0;
  list-style: none;
}


.bootstrap-datetimepicker-widget {
  z-index: 999999;
td, th {
  padding: 10px!important;
}

ul {
  margin-bottom: 0;
}

.datepicker-days {
  td {
    cursor: pointer;


    &:hover {
      background: rgba(255, 255, 255, 0.5);
      color: $gray-dark;
    }

    &.day.active {
      background: rgba(255, 255, 255, 0.7);
      color: $gray-dark;
    }

    &.day.old {
      color: rgba(255, 255, 255, 0.5);
    }

    &.month {
      padding: 20px;
    }
  }
}

.datepicker-months, .datepicker-years {
  td {
    max-width: 300px;
    span {
      cursor: pointer;
      padding: 10px;
      display: inline-block;


      &:hover {
        background: rgba(255, 255, 255, 0.5);
        color: $gray-dark;
      }
    }
  }
}

.picker-switch {
  text-align: center;

  table {
    width: 100%;
  }

  td {
    text-align: center;
    cursor: pointer;

    &:hover {
      background: rgba(255, 255, 255, 0.5);
      color: $gray-dark;
    }
  }
}
}
