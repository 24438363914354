@import 'variables.scss';

table.table tbody tr {
  height: auto;
}

table.table tbody tr.tr-dark-bg td {
  padding-right: 15px !important;
}

.app-table-filter-padded {
  padding: 5px !important;
}

.tr-dark-bg td {
  font-weight: bold;
  font-size: 14px;
}

.box-header {
}

.box-header h2 {
  font-size: 18px;
}

.dashboard-management .main .customer-details > div:first-child {
  padding: 2px;
}

.dashboard-management .main .customer-details {
  padding: 5px;
}

.padded {
  padding: 10px;
}

.dashboard-management .customers-main {
  padding: 5px 0 0 0;
}

hr {
  margin: 0.5rem 0;
}

dt,
dd {
  margin-bottom: 5px;
}

.pending {
  background: rgba(110, 110, 110, 0.8) !important;
}
