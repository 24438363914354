.dashboard-main {
  max-width: calc(100% - 285px);
  width: 100%;
  float: right;
  margin-left: 25px;
  padding-right: 25px;
  transition: width 0.3s ease-in-out;
  position: relative;
  z-index: 9;
  &.full-width {
    max-width: calc(100% - 65px);
  }
  h1 {
    color: #fff;
    font-family: $lato;
    font-size: 34px;
    font-weight: 300;
    margin-top: 25px;
    margin-bottom: 20px;
  }
}
.main-inner {
  padding-bottom: 40px;
  display: flex;
  flex-wrap: wrap;

  div.widget {
    width: 33.33%;
    // max-width: 33%;
    min-height: 350px;
    position: relative;
    @include dark-blur;
    display: block;
    padding: 3px 0;
    margin: 0 auto;
    margin-bottom: 10px;
    overflow: hidden;
    &.widget-auto-height {
      min-height: 270px;
    }
    &.widget-full {
      width: 100%;
      height: 100%;
      min-height: 100%;
      margin: 0 auto;
      @include laptop {
        width: 100%;
        max-width: 100%;
      }
      // max-width: 1200px;
    }
    @include laptop {
      width: 48%;
      max-width: 50%;
    }
    @include tablet {
      width: 100%;
      max-width: 100%;
    }
  }
  .widget-best-sellers {
    ul {
      li {
        &:last-child {
          width: 100px;
          text-align: right;
          p {
            text-align: left;
          }
        }
      }
    }
  }
  .widget-header {
    height: 50px;
    position: relative;
    z-index: 1;
    padding: 12px;
    background: rgba(89, 97, 107, 0.7);
    margin: 3px 2px 10px 2px;
    .widget-title {
      font-family: $lato;
      font-size: 22px;
      color: #fff;
      font-weight: 300;
      // &::before {
      //   content: "\f09d";
      //   font-family: FontAwesome;
      // }
    }
  }
  .widget-inner {
    background: rgba(89, 97, 107, 0.7);
    position: relative;
    color: #fff;
    padding: 20px;
    margin: 0 2px 0 2px;
    height: 85%;
    &.widget-inner-full {
      min-height: 100%;
      height: 100%;
    }
    // max-height: 251px;
    //overflow: hidden;
    // overflow-y: scroll;
  }

  ul {
    display: flex;
    flex-wrap: wrap;
    margin: 0 0 10px 0;
    padding: 0;
    list-style: none;
    flex: 1;
    li {
      .fa-star {
        color: #08adef;
        &:nth-child(5) {
          color: #b8f1ff;
        }
      }
      p {
        color: #fff;
        font-size: 16px;
        text-align: center;
        span {
          display: block;
          font-weight: bold;
        }
        @include notebook {
          font-size: 14px;
        }
      }

      &:first-child {
        margin-left: 0;
        text-align: left;
        flex: 1;
      }
      &:last-child {
        margin-right: 0;
        text-align: right;
        // flex: 1;
      }
    }
  }
}
.widget-new-customers {
  i {
    position: absolute;
    font-size: 65px;
    opacity: 0.3;
  }
  .customers-number {
    font-size: 46px;
    text-align: center;
    color: #fff;
    span {
      display: block;
      font-size: 18px;
    }
  }
  ul {
    display: flex;
    margin: 0;
    padding: 0;
    list-style: none;
    flex: 1;
    li {
      // margin: auto;
      flex: 1;
      color: #fff;
      font-size: 16px;
      text-align: center;
      &:first-child {
        margin-left: 0;
        flex: 1;
        text-align: left;
      }
      &:last-child {
        margin-right: 0;
        flex: 1;
        text-align: right;
      }
      @include notebook {
        font-size: 14px;
      }
    }
  }
}
.widget-card-status {
  ul {
    display: flex;
    margin: 0;
    padding: 0;
    list-style: none;
    li {
      margin: auto;
      color: #fff;
      font-size: 16px;
      width: 20%;
      @include notebook {
        font-size: 14px;
      }
      &:first-child {
        margin-left: 0;
        width: 24%;
      }
      &:last-child {
        margin-right: 0;
      }
    }
  }
}
