.orders-page-nav {
  li {
    &:nth-child(1) {
      &::before {
        content: "\f00a ";
         font-family: FontAwesome;
         color: #fff;
      }
    }
    &:nth-child(2) {
      &::before {
        content: "\f141";
         font-family: FontAwesome;
         color: #fff;
      }
    }
    &:nth-child(3) {
      &::before {
        content: "\f252";
         font-family: FontAwesome;
         color: #fff;
      }
    }
    &:nth-child(4) {
      &::before {
        content: "\f275";
         font-family: FontAwesome;
         color: #fff;
      }
    }
    &:nth-child(5) {
      &::before {
        content: "\f00c";
         font-family: FontAwesome;
         color: #fff;
      }
    }
    &:nth-child(6) {
      &::before {
        content: "\f06a";
         font-family: FontAwesome;
         color: #fff;
      }
    }
    &:nth-child(7) {
      &::before {
        content: "\f112";
         font-family: FontAwesome;
         color: #fff;
      }
    }
  }
}

.orders-page-list {
  .top {
    h2 {
      &::before {
        margin-right: 10px;
        content: "\f0f6";
        font-family: FontAwesome;
      }
    }
  }
}
.order-page-list-nav {
  li {
    margin: 0 auto;
  }
}
.list-of-orders {
  ul {
    li {
      p.order-number {
        margin: 0 auto;
        font-size: 22px;
        color: #fff;
        margin-left: 10px;
        width: 23%;
        text-align: center;
        margin-right: 0;
        line-height: 1;
      }
      p.customer {
        margin: 0 auto;
        width: 25%;
        text-align: center;
        line-height: 1;
        color: #fff;
        font-size: 14px;
      }
      p.date {
        line-height: 1;
        margin: 0 auto;
        color: #fff;
        text-align: center;
        font-size: 13px;
      }
      p.status {
        line-height: 1;
        margin: 0 auto;
        color: #fff;
        text-align: center;
        text-transform: uppercase;
        font-size: 14px;
        &::before {
          display: block;
          margin-top: -5px;
          content: "\f141";
           font-family: FontAwesome;
           color: #fff;
        }
      }
    }
  }
}
.dashboard-management {
  .inner{
    .main {
      .order-status {
        position: relative;
        background: $light-brown;
        margin: 2px 2px 0 2px;
        padding: 20px;
        height: auto;
        display: grid;
        grid-template-columns: 1fr;
        @include notebook {
          height: auto;
        }
        > div {
          &:first-child {
            display: grid;
            // grid-template-columns: 1fr 1fr 1fr 1fr;
            grid-template-columns: 30% 20% 20% 30%;
            border-bottom: 1px solid #fff;
          }
          &:last-child {
            display: grid;
            grid-template-columns: 30% 20% 20% 30%;
            padding-top: 10px;
          }
          p.order-number {
            color: #fff;
            font-family: $lato;
            font-size: 22px;
            margin: 0;
          }
          p.recipient {
            color: #fff;
            font-family: $lato;
            font-size: 18px;
            margin: 0;
            @include notebook {
              font-size: 16px;
            }
            &::before {
              content: "\f0c0";
              font-family: FontAwesome;
              margin-right: 5px;
            }
            span {
              color: $blue-hover;
            }
          }
          p.quantity {
            color: #fff;
            font-family: $lato;
            font-size: 18px;
            margin: 0;
            @include notebook {
              font-size: 16px;
            }
            &::before {
              content: "\f24e";
              font-family: FontAwesome;
              margin-right: 5px;
            }
          }
          p.total-price {
            color: #fff;
            font-family: $lato;
            font-size: 18px;
            margin: 0;
            @include notebook {
              font-size: 16px;
            }
            &::before {
              content: "\f1c0";
              font-family: FontAwesome;
              margin-right: 5px;
            }
          }
          .order-details {
            p {
              color: #fff;
              font-size: 15px;
              display: block;
              margin: 0;
              @include notebook {
                font-size: 14px;
              }
            }
          }
          .billing-adress {
            p {
              color: #fff;
              font-size: 15px;
              display: block;
              margin: 0;
              @include notebook {
                font-size: 14px;
              }
            }
          }
          .payment-option {
            p {
              color: #fff;
              font-size: 15px;
              display: block;
              margin: 0;
              @include notebook {
                font-size: 14px;
              }
            }
          }
          .price-status {
            p {
              color: #fff;
              font-size: 34px;
              display: block;
              text-transform: uppercase;
              margin: 0;
              text-align: center;
            }
          }
        }
      }

        .product-info {
          position: relative;
          margin: 10px 0 0 0;
          display: grid;
          grid-template-columns: 1fr;
          height: max-content;

          .top {
            background: $light-brown;
            margin: 2px;
            display: grid;
            grid-template-columns: 1fr 1fr;
            > div {
              display: flex;
              height: 50px;
              padding-top: 14px;
              p {
                margin: 0 auto;
                color: #fff;
                display: inline-block;
                @include notebook {
                  font-size: 14px;
                }
                &:first-child {
                  margin-left: 10px;
                }
              }
              p.quantity {
                &::before {
                  content: "\f24e";
                  font-family: FontAwesome;
                  margin-right: 5px;
                }
              }
              p.amount {
                &::before {
                  content: "\f1c0";
                  font-family: FontAwesome;
                  margin-right: 5px;
                }
              }
            }
          }
          .middle {
            margin-left: 2px;
            margin-right: 2px;
            grid-gap: 2px;
            display: grid;
            grid-template-columns: 1fr 1fr;
            @include laptop {
              grid-template-columns: 1fr 0.5fr;

            }
            > div {
              padding: 20px;
              background: $light-brown;
              display: flex;
              align-items: end;
              @include notebook {
                flex-wrap: wrap;
              }
              &:last-child {
                display: grid;
                grid-template-columns: 1fr;
                align-items: start;
                > div {
                  &:first-child {
                    display: grid;
                    grid-template-columns: 1fr;
                    grid-gap: 20px;
                    p {
                      &:first-child {
                        font-weight: bold;
                        i {
                            margin-right: 5px;
                        }

                      }
                    }
                  }
                  p {
                    font-size: 15px;
                    color: #fff;
                    margin: 0 auto;
                    @include notebook {
                      font-size: 14px;
                    }
                  }
                }
              }
              a.see-details {
                margin: 0 auto;
                font-size: 16px;
                color: #fff;
                border-radius: 5px;
                padding: 10px;
                text-align: center;
                background: #a0a0a0;
                @include notebook {
                  font-size: 14px;
                }
              }
              img {
                margin: 0 auto;
                max-width: 100%;
                @include notebook {
                max-width: 45%;
                }
              }
            }
          }
        }
      }
    }
  }
