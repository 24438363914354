.credit-card-management {

}
.activated-cards {
    @include dark-blur;
    position: relative;
    // margin-right: 25px;

  .header {
    position: relative;
    background: $light-brown;
    margin: 2px;
    padding-top: 12px;
    padding-left: 25px;
    height: 100%;
  h2 {
    font-size: 22px;
    color: #fff;
    font-family: $lato;
    font-weight: 300;
    &::before {
      content: "\f011";
      font-family: FontAwesome;
      margin-right: 10px;
      }
    }
  }
  .holder {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 10px;
    margin-top: 10px;
    position: relative;
    > div {
      position: relative;
      background: $light-brown;
      min-height: 100px;
      padding-top: 15px;
      margin: 0 2px 10px 2px;
      h3 {
        color: #fff;
        font-size: 18px;
        text-align: center;
        span {
          display: block;
          font-size: 44px;
        }
      }
    }
  }
}
.analitycs-holder {
  // display: grid;
  // grid-template-columns: 66.4% 33%;
  // grid-gap: 10px;
  margin-top: 10px;
  margin-right: 0;
  .row {
    margin-left: 0;
    margin-right: 0;
  }
  .activation-analytics {
    @include dark-blur;
    position: relative;
    min-height: 400px;
    padding: 0;
    ul.activation-nav {
      position: relative;
      width: 100%;
      height: 40px;
      border-bottom: 1px solid #fff;
      display: flex;
      margin: 25px 0 0 0;
      padding: 0 25px 0 25px;
      list-style: none;
      @include notebook {
        height: auto;
        padding: 0 10px 0 10px;
      }
      .downlad-csv {
        position: absolute;
        right: 25px;
        top: 50%;
        transform: translate(0, -50%);
        @include trans;
        &::after {
          content: '\f045';
          font-family: FontAwesome;
          margin-left: 10px;
        }
        &:hover {
          color: $blue-hover;
        }
      }
      li {
        position: relative;
        // margin: 0 auto;
        max-width: 25%;
        flex: 1;
        text-align: center;
        padding: 5px;
        border: 1px solid transparent;
        border-bottom: none;
        @include trans;
        @include laptop {
          max-width: 35%;
        }
        a {
          @include trans;
          &:hover {
            color: $blue-hover;
          }
          &:focus {
            color: #fff;
          }
        }

        &:nth-child(1) {
          &::before {
            content: "\f201";
            font-family: FontAwesome;
            margin-right: 5px;
            color: #fff;
          }
        }
        &:nth-child(2) {
          &::before {
            content: "\f017";
            font-family: FontAwesome;
            margin-right: 5px;
            color: #fff;
          }
        }

      }
      .active {
        border-color: #fff;
        border-bottom: 0;
      }

    }
    a.analitycs {
      position: relative;
      min-width: 200px;
      float: right;
      border: 1px solid #fff;
      padding: 5px 15px 5px 15px;
      text-align: center;
      color: #fff;
      margin: 10px  ;
      border-radius: 5px;
      @include trans;
      &:hover {
        // background: lighten($brown, 15%);
        border-color: $blue-hover;
        color: $blue-hover;
      }
      @include notebook {
        font-size: 14px;
        padding: 5px;
      }

    }

  }
  .activated-analytics {
    @include dark-blur;
    position: relative;
    min-height: 400px;
    padding: 0;
    .activated-by {
      position: absolute;
      margin: 10px;
      width: 100%;
      p {
        color: #fff;
        display: inline-block;
        margin-right: 25px;
      }
    }
  }
}

.select-holder {
  position: relative;
  max-width: 299px;

}
.select-holder .select2-results {
  background: $light-brown !important;
}
.select-holder .select2-dropdown {
  background: $light-brown !important;
  max-width: 124px;
}
.select-holder .select2-container--default .select2-selection--single {
    background: transparent;
    border: 1px solid #aaa;
  }
.select-holder  .select2-container--default .select2-selection--single .select2-selection__rendered {
    background: transparent;
  }
.select-holder  .select-holder.select2-container--default .select2-results > .select2-results__options {
    background: $light-brown !important;
  }
.select-holder   .select2-results__options {
    background: $light-brown !important;
  }
